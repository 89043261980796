@use '@/styles/utils/mixins.scss' as *;

.ctaBannerWrapper {
  margin-top: 3.75rem;
  margin-bottom: 15.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.75rem;
  @include tab() {
    margin-top: 5.125rem;
    margin-bottom: 12.5rem;
  }

  .ctaBannerContent {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 3.75rem;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .ctaBannerTitle {
    display: flex;
    flex-direction: column;
    span {
      color: $dark;
    }
    @include tab() {
      text-align: center;
      align-items: center;
    }
  }

  .description {
    width: 100%;
    max-width: 55.5rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      font-size: 1.5rem;
      @include tab() {
        font-size: 1.25rem;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }

  .productCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    padding: 0 2.5rem;

    @include mob() {
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
    }
  }
}
